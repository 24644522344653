import firebase from "firebase"
import axios from "axios"
import {
  patientEnrollmentValidationSchema,
  doctorEnrollmentValidationSchema,
} from "../utils/formData"
import {
  GATSBY_HAS_AUTH_ENABLED,
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_PATIENT_ROLE_ID,
  GATSBY_LOGIN_REDIRECT,
  GATSBY_SEND_MD_CONFIRMATION_WEBHOOK,
  GATSBY_SEND_PX_CONFIRMATION_WEBHOOK,
  GATSBY_SUBMIT_DOCTOR_ENROLLMENT_WEBHOOK,
  GATSBY_SUBMIT_PATIENT_ENROLLMENT_WEBHOOK,
} from "gatsby-env-variables"
import { sendEmail } from "../../../services/sendEmail"
import { sendToZendesk } from "../../../services/zendeskService"

import { getSignedInUser } from "../../Auth/services/user"
import { handleSignOut } from "../../Auth/services/signout"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"

import { isBrowser } from "services/general"

export const shouldPatientButtonBeDisabled = ({ formData }) => {
  if (
    formData?.agreeToConsent?.length < 1 ||
    formData?.agreeToTermsAndConditions?.length < 1
  )
    return true
  else {
    let isFormValid
    isFormValid = patientEnrollmentValidationSchema
      .isValid({
        ...formData,
      })
      .then((valid) => {
        isFormValid = valid
        return isFormValid
      })

    return !isFormValid
  }
}

function formatName(firstName, lastName, middleInitial) {
  const MI = middleInitial ? ` ${middleInitial} ` : " "
  return `${firstName}${MI}${lastName}`
}

const capitalize = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const shouldDoctorButtonBeDisabled = ({ formData }) => {
  if (
    formData?.agreeToConsent?.length < 1 ||
    formData?.agreeToTermsAndConditions?.length < 1
  )
    return true
  else {
    let isFormValid
    isFormValid = doctorEnrollmentValidationSchema
      .isValid({
        ...formData,
      })
      .then((valid) => {
        isFormValid = valid
        return isFormValid
      })

    return !isFormValid
  }
}

export const sendEnrollmentForm = async (config) => {
  console.log(config)
  const SEND_CONFIRMATION_WEBHOOK =
    config?.type === "doctor"
      ? GATSBY_SEND_MD_CONFIRMATION_WEBHOOK
      : GATSBY_SEND_PX_CONFIRMATION_WEBHOOK
  const SUBMIT_ENROLLMENT_WEBHOOK =
    config?.type === "doctor"
      ? GATSBY_SUBMIT_DOCTOR_ENROLLMENT_WEBHOOK
      : GATSBY_SUBMIT_PATIENT_ENROLLMENT_WEBHOOK
  const IS_AUTH_ENABLED = GATSBY_HAS_AUTH_ENABLED === "enabled" ? true : false
  let { errorCallback, callback, enrollmentData } = config

  console.log(SEND_CONFIRMATION_WEBHOOK)

  try {
    if (
      IS_AUTH_ENABLED &&
      isBrowser() &&
      !window.location.pathname.includes("doctor")
    ) {
      let { addresses, userData } = getSignedInUser()
      let addressesId = addresses?.id || ""
      let addressesList = addresses?.addresses || []
      if (!userData?.id)
        userData = await checkIfUserAlreadyExists(enrollmentData.email)
      let authUid = userData?.authUid
      // Create an account if user has no account yet
      if (!authUid) {
        let createdUser = await firebase
          .auth()
          .createUserWithEmailAndPassword(
            enrollmentData.email,
            enrollmentData.password
          )
        authUid = createdUser.user.uid
        createdUser.user.sendEmailVerification({ url: GATSBY_LOGIN_REDIRECT })
      }
      // Get user's roles
      let userRoles =
        userData?.roles?.filter(
          (role) => role.subdomain !== GATSBY_AWS_S3_BUCKET_NAME
        ) || []
      // Get allowed subdomains
      let userSubdomains =
        userData?.allowedSubdomains?.filter(
          (allowedSubdomain) => allowedSubdomain !== GATSBY_AWS_S3_BUCKET_NAME
        ) || []
      // Add  address details
      let addressInformation = {
        primary: true,
        type: enrollmentData?.patientAddress?.addressType?.toLowerCase(),
        streetAddress: enrollmentData?.patientAddress?.streetAddress,
        city: enrollmentData?.patientAddress?.city?.value,
        province: enrollmentData?.patientAddress?.province?.value,
        barangay: enrollmentData?.patientAddress?.barangay?.value,
      }
      let finalAddressList = [...addressesList, { ...addressInformation }]
      if (!addressesId) {
        addressesId = await firebase
          .firestore()
          .collection("addresses")
          .add({ addresses: [...finalAddressList] })
        addressesId = addressesId.id
      } else {
        await firebase
          .firestore()
          .collection("addresses")
          .doc(addressesId)
          .update({ addresses: [...finalAddressList] })
      }
      // Add user details
      let hospitalName =
        enrollmentData?.hospitalName?.value === "Other"
          ? enrollmentData?.otherHospital
          : enrollmentData?.hospitalName?.value
      let { month, date, year } = enrollmentData.birthday
      const USER_DOCUMENT = {
        forceReset: false,
        firstName: enrollmentData.firstName,
        lastName: enrollmentData.lastName,
        middleInitial: enrollmentData.middleInitial,
        mobileNumber: enrollmentData.mobileNumber,
        email: enrollmentData.emailAddress,
        addresses: addressesId,
        birthday: {
          month: month.value,
          date: date.value,
          year: year,
        },
        allowedSubdomains: [...userSubdomains, GATSBY_AWS_S3_BUCKET_NAME],
        programInfo: [
          {
            subdomain: GATSBY_AWS_S3_BUCKET_NAME,
            hospitalClinic: hospitalName,
            doctor: enrollmentData.doctorName,
            medicinePrescribed: enrollmentData.prescribedMedicine,
          },
        ],
        roles: [
          ...userRoles,
          {
            status: "inactive",
            subdomain: GATSBY_AWS_S3_BUCKET_NAME,
            role: GATSBY_FIREBASE_PATIENT_ROLE_ID,
            projectId: GATSBY_FIREBASE_PROJECT_ID,
          },
        ],
      }
      if (userData?.id) {
        await firebase
          .firestore()
          .collection("users")
          .doc(userData?.id)
          .update({ ...USER_DOCUMENT, authUid })
      } else {
        await firebase
          .firestore()
          .collection("users")
          .add({ ...USER_DOCUMENT, authUid })
      }
    }
    // Creates Zendesk ticket
    await sendEnrollmentFormToZendesk(config)
    // Send SMS via Make
    console.log(SEND_CONFIRMATION_WEBHOOK)
    await axios.post(SEND_CONFIRMATION_WEBHOOK, {
      ...enrollmentData,
    })
    await handleSignOut({ redirect: false })
    if (callback) callback()
  } catch (error) {
    console.log(error)
    await axios.post(SUBMIT_ENROLLMENT_WEBHOOK, {
      ...enrollmentData,
      error: error,
    })
    if (errorCallback) await errorCallback()
  }
}

export const sendEnrollmentFormToEmail = (config) => {
  let { enrollmentData, emailTemplate, type } = config
  const { firstName, lastName, middleInitial } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  const subject = `CarePlus Program: ${capitalize(
    type
  )} Enrollment for ${fullname} has been ${
    type === "doctor" ? "Approved" : "Received"
  }`
  return sendEmail({
    to: process.env.GATSBY_AWS_SES_EMAIL_TO,
    cc: enrollmentData.emailAddress,
    from: process.env.GATSBY_AWS_SES_EMAIL_FROM,
    subject,
    html: emailTemplate(enrollmentData),
    bcc: process.env.GATSBY_AWS_SES_EMAIL_BCC,
  })
}

export const sendEnrollmentFormToZendesk = (config) => {
  let { enrollmentData, ticketTemplate, type } = config
  let isTest = process.env.GATSBY_ENV !== "production"

  let tags = [`${type}_enrollment`, process.env.GATSBY_ENV]
  if (isTest) tags.push("test")
  if (enrollmentData?.prescribedMedicine?.tag)
    tags.push(enrollmentData?.prescribedMedicine?.tag)

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type
  )} Enrollment Form of ${enrollmentData?.firstName} ${
    enrollmentData?.lastName
  }`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.emailAddress,
    },
    comment: { body: ticketTemplate(enrollmentData) },
  }

  return sendToZendesk(requestBody, [])
}

export const generatePaymentMethodMedicines = (config) => {
  let { patientEnrollment } = config

  let paymentMethods = [...patientEnrollment?.paymentMethod]

  if (paymentMethods.includes("Government assistance")) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) => paymentMethod !== "Government assistance"
    )

    let governmentAssistance = patientEnrollment?.governmentAssistance
    let otherGovernmentAgency = governmentAssistance.filter(
      (agency) => agency === "Other"
    )

    otherGovernmentAgency = `Other Government Agency (${patientEnrollment?.otherGovernmentAgency})`
    paymentMethods = [
      ...paymentMethods,
      ...governmentAssistance.filter((agency) => agency !== "Other"),
    ]

    if (patientEnrollment?.governmentAssistance?.includes("Other"))
      paymentMethods = [...paymentMethods, otherGovernmentAgency]
  }

  if (paymentMethods.includes("Company employee benefit")) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) => paymentMethod !== "Company employee benefit"
    )
    paymentMethods.push(
      `Company employee benefit (${patientEnrollment?.companyEmployeeBenefits})`
    )
  }

  if (paymentMethods.includes("Private health insurance")) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) => paymentMethod !== "Private health insurance"
    )
    paymentMethods.push(
      `Private health insurance (${patientEnrollment?.privateHealthInsurance})`
    )
  }

  return paymentMethods
}

import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import classNames from "classnames"
import { Formik, Form } from "formik"

import Section from "../Elements/Section"
import FormCheckbox from "../Elements/Form/FormCheckbox"

import styles from "./utils/summary.module.scss"
import { AppContext } from "../../context/AppContext"
import { consentAndAuthValidationSchema } from "./utils/formData"
import { getTermsAndConditionsLink } from "./services/getTermsAndConditionsLink"

const ConsentAndAuthSection = ({ title, data }) => (
  <Fragment>
    <h6 className="mt-0">{title}</h6>
    <ul className="help">
      {data.map((item) => (
        <li dangerouslySetInnerHTML={{ __html: item }} />
      ))}
    </ul>
  </Fragment>
)

const consentBlurb = `<span>I agree to the consent and authorization.</span>`

const ConsentAndAuthorization = ({
  children,
  flow,
  handleOnSubmit,
  termsAndConditionsData,
  additionalCheckboxes,
}) => {
  let currentTermsAndConditionsBlurb = getTermsAndConditionsLink({
    module: flow,
  })
  const CONSENT_REF = useRef()
  const { dispatch } = useContext(AppContext)
  const [enabled, setEnabled] = useState(false)

  const handleObserver = (entities) => {
    const TARGET = entities?.[0]
    if (TARGET.isIntersecting) setEnabled(true)
  }

  useEffect(() => {
    let options = {
      root: null,
      threshold: 1.0,
    }
    const OBSERVER = new IntersectionObserver(handleObserver, options)
    if (CONSENT_REF.current) OBSERVER.observe(CONSENT_REF.current)
  }, [])

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2 mx-0-mobile">
        {termsAndConditionsData && (
          <article className="message mb-1">
            <div className={classNames("message-body", styles["collapsible"])}>
              <section className="content">
                {Object.keys(termsAndConditionsData || {}).map((key) => (
                  <ConsentAndAuthSection
                    title={termsAndConditionsData[key].title}
                    data={termsAndConditionsData[key].info}
                  />
                ))}
                <div className="ref" ref={CONSENT_REF}></div>
              </section>
            </div>
          </article>
        )}
        {!enabled ? (
          <p className="help is-danger mb-1">
            Please read the terms and conditions first to proceed
          </p>
        ) : null}
        <Formik
          initialValues={{ agreeToConsent: [], agreeToTermsAndConditions: [] }}
          validationSchema={consentAndAuthValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              {additionalCheckboxes || null}
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={[consentBlurb]}
                isRequired
                onChange={(event) =>
                  dispatch({
                    type: `SAVE_AGREE_CONSENT_${flow.toUpperCase()}`,
                    payload: event.target.checked ? [consentBlurb] : [],
                  })
                }
                disabled={!enabled}
              />
              <FormCheckbox
                name="agreeToTermsAndConditions"
                values={values.agreeToTermsAndConditions}
                options={[currentTermsAndConditionsBlurb]}
                isRequired
                onChange={(event) =>
                  dispatch({
                    type: `SAVE_AGREE_${flow.toUpperCase()}`,
                    payload: event.target.checked
                      ? [currentTermsAndConditionsBlurb]
                      : [],
                  })
                }
                disabled={!enabled}
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization

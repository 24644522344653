import React, { Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

import ErrorMessage from "elements/ErrorMessage"
import { hasIncompleteValues } from "../services/form"

const ClinicInformationSummary = ({ type, clinicDetails }) => (
  <div
    className={classNames("mb-3", {
      "mt-3": type === "secondary",
    })}
  >
    <p className="has-text-weight-bold">
      <span className="is-capitalized has-text-weight-normal">
        {type} Clinic:
      </span>{" "}
      {clinicDetails?.name?.value !== "Other"
        ? clinicDetails?.name?.value
        : clinicDetails?.otherHospital}
    </p>
    <p>
      {clinicDetails?.clinicHours &&
        `${clinicDetails?.clinicHours} | ${clinicDetails?.clinicContactNumber}`}
    </p>
    <p>
      <span className="icon is-small">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </span>{" "}
      {clinicDetails?.streetAddress && `${clinicDetails?.streetAddress}, `}{" "}
      {clinicDetails?.city?.value && `${clinicDetails?.city?.value}, `}
      {clinicDetails?.province?.value}
    </p>
  </div>
)

const ClinicsInformationSummary = ({ doctorEnrollment }) => {
  if (
    hasIncompleteValues({
      data: doctorEnrollment.primaryClinic,
      flow: "doctorEnrollmentClinicInformation",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <Fragment>
      <ClinicInformationSummary
        type="primary"
        clinicDetails={doctorEnrollment?.primaryClinic}
      />
      {doctorEnrollment?.secondaryClinic?.name?.value && (
        <ClinicInformationSummary
          type="secondary"
          clinicDetails={doctorEnrollment?.secondaryClinic}
        />
      )}
    </Fragment>
  )
}

export default ClinicsInformationSummary

export const doctorEnrollmentTicketBody = (request) => {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    prcLicenseNumber,
    specialization,
    otherSpecialization,
    primaryClinic,
    secondaryClinic,
    referral,
  } = request

  return `Personal Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  PRC License Number: ${prcLicenseNumber}
  Specialization: ${
    specialization?.value === "Other"
      ? otherSpecialization
      : specialization?.value
  }
  ---
  Primary Office
  Hospital/Clinic: ${
    primaryClinic?.name?.value !== "Other"
      ? primaryClinic?.name?.value
      : primaryClinic?.otherHospital
  }
  Clinic Hours: ${primaryClinic?.clinicHours}
  Clinic Contact Number: ${primaryClinic?.clinicContactNumber}
  Street Address: ${primaryClinic?.streetAddress}
  City: ${primaryClinic?.city?.value}
  Province: ${primaryClinic?.province?.value}
  ---
  Secondary Office
  Hospital/Clinic: ${
    secondaryClinic?.name?.value !== "Other"
      ? secondaryClinic?.name?.value
        ? secondaryClinic?.name?.value
        : "N/A"
      : secondaryClinic?.otherHospital || "N/A"
  }
  Clinic Hours: ${secondaryClinic?.clinicHours || "N/A"}
  Clinic Contact Number: ${secondaryClinic?.clinicContactNumber || "N/A"}
  Street Address: ${secondaryClinic?.streetAddress || "N/A"}
  City: ${
    (secondaryClinic?.name?.value && secondaryClinic?.city?.value) || "N/A"
  }
  Province: ${secondaryClinic?.province?.value || "N/A"}
  ---
  Referral
  Full Name: ${referral?.fullName || "N/A"}
  `
}

import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import DoctorEnrollmentSummary from "../../components/Enrollment/DoctorEnrollmentSummary"

export default () => (
  <Layout
    title="Enrollment Summary"
    subtitle={
      <span>
        Please review if the following information is correct.
        <br />
        Your information will be used as a basis for your enrollment.
      </span>
    }
    seoTitle="Enrollment Summary"
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <DoctorEnrollmentSummary />
    </Container>
  </Layout>
)

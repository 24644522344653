const doctorEnrollmentTemplate = (data) => {
  const {
    firstName,
    lastName,
    middleInitial,
    mobileNumber,
    emailAddress,
    prcLicenseNumber,
    primaryClinic,
    secondaryClinic,
    referral,
    doNotNotify,
    specialization,
    otherSpecialization,
    teleconsultFee,
    teleconsultHours,
    contactThrough,
    bestTimeToCall,
    callFrequency,
  } = data
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
  <head>
    <style>
      body {
        font-family: "Lato", sans-serif;
        line-height: 1.25;
      }

      @media only screen and (max-width: 770px) {
        .logo {
          width: 100%;
        }
      }

      @media screen {
        .logo {
          width: 30%;
        }
      }

      .subtitle {
        margin-top: 2.25rem;
      }

      b {
        color: #0d6d6e;
      }

      .content {
        width: 95%;
        margin: auto;
      }

      @media only screen and (min-width: 770px) {
        .content {
          width: 60%;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      table,
      th,
      td {
        border: 1px solid #ccc;
      }

      td {
        padding: 10px;
      }

      h3 {
        margin-top: 0.75rem;
        color: #0d6d6e;
      }

      .terms {
        padding: 10px;
        background: #eeeeee;
        font-size: 11px;
        color: #606060;
      }

      .has-text-right {
        text-align: right;
      }

      .terms th,
      .terms td {
        padding: 5px;
      }
    </style>
  </head>

  <body>
    <div class="container">
      <div class="content">
      <img
          src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
          class="logo"
        />
        <article style="background-color: rgba(254,248,236,.501961) !important; border-radius: 4px; display: block; margin-top: 1.5em"><div style="border-color: rgba(250,222,164,.501961) !important; color: #333 !important; border-radius: 4px; border: solid #cfe1e1; border-width: 0 0 0 4px;padding: 1.25em 1.5em; font-size: 14px; font-weight: bold">Hi. We have received your request. Please expect delays as many of our team members are quarantined. Thank you for your understanding.</div></article>

        <p class="subtitle">Hi Dr. <b>${firstName} ${lastName}</b>,</p>
        <p>
          Thank you for choosing to be a part of the CarePlus
          Program. This email serves as the official confirmation that your
          enrollment to the program has been <strong>APPROVED</strong>. We have
          included in this email the important details for you to get started.
        </p>

        <p class="subtitle is-primary">
          <b>Enroll your Patients</b>
          <ol>
            <li>Instruct your patients to enroll in the program once you receive this email.</li>
            <li>Receive physical copies of the CarePlus communication materials.</li>
            <li>Download the communication materials <a
            href="https://drive.google.com/file/d/1I8kfQaujf-OHgNaXJxe5CNlcg7mWwCPG/view?usp=sharing"
            target="blank"
            rel="noreferrer noopener"
          >
            here</a> while waiting for your physical copies.</li>
          </ol>
        </p>

        <p>
        For any questions about the program, please don't hesitate to contact us at
          <a href="mailto:careplus@medgrocer.com" target="_blank"
            >careplus@medgrocer.com</a
          >.
        </p>

        <br />
        <p>
          Regards, <br />
          <strong>The CarePlus Team</strong>
        </p>
        <br />
        <hr style="background-color: #ccc;" />
        <p class="subtitle is-primary">
          <b>Review Your Responses</b>
        </p>
        <p>
        Please review your responses and make sure all details are correct. If you would like to make any revisions, please send an email to 
          <a
            href="mailto:careplus@medgrocer.com?Subject=Change%20Doctor%20Enrollment%20Details%20-%20${firstName}%20${lastName}"
            target="_blank"
            >careplus@medgrocer.com</a>.
        </p>
        <br />

        <table class="table">
          <tbody>
            <tr>
              <td colspan="2"><b>Personal Details</b></td>
            </tr>
            <tr>
              <td width="30%">Last Name</td>
              <td>${lastName}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>${firstName}</td>
            </tr>
            <tr>
              <td>Middle Initial</td>
              <td>${middleInitial || "N/A"}</td>
            </tr>
            <tr>
              <td>Mobile Number</td>
              <td>${mobileNumber}</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>${emailAddress || "N/A"}</td>
            </tr>
            <tr>
              <td>PRC License Number</td>
              <td>${prcLicenseNumber}</td>
            </tr>
            <tr>
              <td>Specialization</td>
              <td>${
                specialization?.value === "Other"
                  ? otherSpecialization
                  : specialization?.value
              }</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <table class="table">
          <tbody>
            <tr>
              <td colspan="2"><b>Primary Office</b></td>
            </tr>
            <tr>
              <td width="30%">Hospital/Clinic</td>
              <td>${
                primaryClinic?.name?.value !== "Other"
                  ? primaryClinic?.name?.value
                  : primaryClinic?.otherHospital
              }</td>
            </tr>
            
            <tr>
              <td>Clinic Hours</td>
              <td>${primaryClinic.clinicHours}</td>
            </tr>
            <tr>
              <td>Clinic Contact Number</td>
              <td>${primaryClinic.clinicContactNumber}</td>
            </tr>
            <tr>
              <td>Street Address (Floor, Building, Street)</td>
              <td>${primaryClinic.streetAddress}</td>
            </tr>
            <tr>
              <td>Province</td>
              <td>${primaryClinic.province.value}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>${primaryClinic.city.value}</td>
            </tr>
            <tr>
              <td colspan="2"><b>Secondary Office</b></td>
            </tr>
            <tr>
              <td>Hospital/Clinic</td>
              <td>${
                secondaryClinic?.name?.value !== "Other"
                  ? secondaryClinic?.name?.value
                    ? secondaryClinic?.name?.value
                    : "N/A"
                  : secondaryClinic?.otherHospital || "N/A"
              }</td>
            </tr>
            <tr>
              <td>Clinic Hours</td>
              <td>${secondaryClinic.clinicHours || "N/A"}</td>
            </tr>
            <tr>
              <td>Clinic Contact Number</td>
              <td>${secondaryClinic.clinicContactNumber || "N/A"}</td>
            </tr>
            <tr>
              <td>Street Address (Floor, Building, Street)</td>
              <td>${secondaryClinic.streetAddress || "N/A"}</td>
            </tr>
            <tr>
              <td>Province</td>
              <td>${
                (secondaryClinic?.name?.value &&
                  secondaryClinic.province.value) ||
                "N/A"
              }</td>
            </tr>
            <tr>
              <td>City</td>
              <td>${secondaryClinic.city.value || "N/A"}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />

        <table class="table">
          <tbody>
            <tr>
              <td colspan="2"><b>Teleconsult Information</b></td>
            </tr>
            <tr>
              <td width="30%">Teleconsult Hours</td>
              <td>${teleconsultHours || "N/A"}</td>
            </tr>
            <tr>
              <td>Teleconsult Fee</td>
              <td>${teleconsultFee || "N/A"}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <table class="table">
        <tr>
        <tr>
          <td colspan="2"><b>Referral</b></td>
        </tr>
        <tr>
          <td width="30%">Full Name</td>
          <td>${referral?.fullName || "N/A"}</td>
        </tr>
        </table>
        <br />
        <br />
        <table class="table">
          <tbody>
            <tr>
              <td colspan="2"><b>Communication Preferences</b></td>
            </tr>
            <tr>
              <td width="30%">You want to be contacted through:</td>
              <td>${contactThrough}</td>
            </tr>
            <tr>
              <td width="30%">When is the best time to call you:</td>
              <td>${bestTimeToCall?.join(", ") || "N/A"}</td>
            </tr>
            <tr>
              <td width="30%">To help you manage expectations and concerns, how often can we call you?</td>
              <td>${callFrequency || "N/A"}</td>
            </tr>
            <tr>
              <td width="30%">Do you want to receive your patients' adherence information?</td>
              <td>${
                doNotNotify?.includes("My patients' adherence information")
                  ? "Yes"
                  : "No"
              }</td>
            </tr>
            <tr>
              <td width="30%">Do you want to receive Patient Educational Materials?</td>
              <td>${
                doNotNotify?.includes("Patient educational materials")
                  ? "Yes"
                  : "No"
              }</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <div class="terms">
          <p>
            This email serves as confirmation that you have agreed to the
            following <b>Terms and Conditions</b>:
          </p>

          <b>Data Privacy</b>
          <ul>
            <li>The above information is correct and I give Johnson & Johnson Philippines Inc. (“J&J Philippines”) and MedGrocer the authority to collect, store, and use my information for the CarePlus Program (“Program”) and its related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the Program without any further consent needed from my end.</li> 
            <li>J&J Philippines and MedGrocer acknowledge and respect the privacy of individuals.</li>
            <li>J&J Philippines has engaged MedGrocer to collect and hold my information in order to organize my participation in the Program and to communicate with me regarding the Program. By completing this form and providing the information requested, I consent to J&J Philippines, MedGrocer, and their partners using that information for the purposes outlined.  J&J Philippines and MedGrocer may access and disclose the information to respond to regulatory authorities or if required by law.</li>
            <li>I am not obliged to provide the information and I may choose not to. If I do not provide this information, J&J Philippines and MedGrocer may not be able to provide the needed support to me under the Program or administer the Program effectively or at all.</li>
            <li>If I would like to access, update, or correct my information or if I do not want my information retained on the database, I should contact MedGrocer.</li>
          </ul>
          <b>Program End</b>
          <ul>
            <li>
            I understand that J&J Philippines and MedGrocer have the right to terminate the Program prior to the end date and their representative may retrieve all the remaining communication materials.
            </li>
          </ul>
          <b>Waiver</b>
          <ul>
            <li>
            I will indemnify and hold J&J Philippines, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Program. I also acknowledge that J&J Philippines or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </body>
</html>
`
}

export default doctorEnrollmentTemplate

import requiredFields from "../utils/requiredFields.json"

export const hasIncompleteValues = ({ data, flow }) => {
  for (let i = 0; i < requiredFields[flow].length; i++) {
    let keys = requiredFields[flow][i].split(".")
    let finalValue = keys.reduce((object, key) => object[key], data)

    if (!finalValue) return true
  }
  return false
}

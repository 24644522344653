import React, { Fragment } from "react"
import ErrorMessage from "elements/ErrorMessage"
import { hasIncompleteValues } from "../services/form"

const PersonalInformationSummary = ({ doctorEnrollment }) => {
  if (
    hasIncompleteValues({
      data: doctorEnrollment,
      flow: "doctorEnrollmentPersonalInformation",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <Fragment>
      <p className="has-text-weight-bold">
        {doctorEnrollment?.firstName}
        {doctorEnrollment?.middleInitial
          ? ` ${doctorEnrollment?.middleInitial}. `
          : " "}
        {doctorEnrollment?.lastName}
      </p>
      <p>
        {doctorEnrollment?.mobileNumber} | {doctorEnrollment?.emailAddress}
      </p>
      <p>
        {doctorEnrollment?.prcLicenseNumber},{" "}
        {doctorEnrollment?.specialization?.value === "Other"
          ? doctorEnrollment?.otherSpecialization
          : doctorEnrollment?.specialization?.label}
      </p>
    </Fragment>
  )
}

export default PersonalInformationSummary
